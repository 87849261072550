import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./NewsGallery.module.css";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../../Assests/Images/Cross.svg";
import { CONSTANT } from "../../../../Utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toster from "../../../../Common/Toster/Toster";
import Loader from "../../../../Common/Loader/Loader";
import { userLogoutClear } from "../../../../redux/slices/userSlice";
import { STATUS_MSG } from "../../../../Utils/StatusMsg";
import { STATUS_CODE } from "../../../../Utils/StatusCode";
import TrainzaApi from "../../../../Helpers/Api";
import {
  capitalizeFirstLetter,
  modifySingularPlural,
} from "../../../../Utils/CommonFunction";
import DeleteResult from "../../../Members/MemberDetail/DeleteResult";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

function NewsGallery({ eventId, eventTitle }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateSeq, setIsUpdateSeq] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [binaryImg, setBinaryImg] = useState(null);
  const [uploadedImg, setUploadedImg] = useState([]);

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files;

    setBinaryImg(file);
  };

  const handlePostImage = () => {
    const singOrPlu =
      CONSTANT.MAX_IMG_LIMIT - uploadedImg?.length === 1 ? "image" : "images";

    if (binaryImg.length > CONSTANT.MAX_IMG_LIMIT - uploadedImg?.length) {
      Toster(
        t(
          `You have to upload only ${
            CONSTANT.MAX_IMG_LIMIT - uploadedImg?.length
          } ${singOrPlu} because gallery stores max ${
            CONSTANT.MAX_IMG_LIMIT
          } images`
        ),
        "error"
      );
    }

    const PostData = new FormData();
    for (var i = 0; i < binaryImg.length; i++) {
      PostData.append("images", binaryImg[i]);
    }

    if (binaryImg?.length <= CONSTANT.MAX_IMG_LIMIT - uploadedImg?.length) {
      setIsLoading(true);
      TrainzaApi.PostNewsImage(isAuthenticated, PostData, eventId).then(
        (response) => {
          setIsLoading(false);

          const ResponseCode = STATUS_MSG[response?.data?.code];

          if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.SESSION_EXPIRED
          ) {
            dispatch(userLogoutClear());
            Toster(t("SESSION_EXPIRED"), "error");
          } else {
            if (response?.code === STATUS_CODE.SUCCESS) {
              Toster(t("IMAGE_ADDED"), "success");
              handleGetEventDetail();
              setBinaryImg(null);
            } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
              Toster(response?.data?.message, "error");
            } else {
              Toster(t(ResponseCode), "error");
            }
          }
        }
      );
    }
  };

  const handleGetEventDetail = () => {
    setIsLoading(true);
    TrainzaApi.GetNewsDetail(isAuthenticated, eventId).then(
      async (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          // Assuming data.images is an array of image URLs
          const imageDimensionsPromises = response?.data?.newsGallery.map(
            (imageItem) => getImageDimensions(imageItem)
          );
          const dimensions = await Promise.all(imageDimensionsPromises);
          setUploadedImg(dimensions);
        }
      }
    );
  };

  useEffect(() => {
    handleGetEventDetail();
  }, []);

  useEffect(() => {
    if (binaryImg) {
      handlePostImage();
    }
  }, [binaryImg]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = useCallback(
    (e, newIndex) => {
      e.preventDefault();
      const draggedImageIndex = Number(e.dataTransfer.getData("index"));
      if (draggedImageIndex !== newIndex) {
        const draggedBinary = uploadedImg[draggedImageIndex];
        const newBinary = [...uploadedImg];
        newBinary.splice(draggedImageIndex, 1);
        newBinary.splice(newIndex, 0, draggedBinary);
        setUploadedImg(newBinary);
        setIsUpdateSeq(true);
      }
    },
    [uploadedImg]
  );

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const lgRef = useRef(null);

  const closeGallery = () => {
    if (lgRef.current) {
      lgRef.current.instance.closeGallery();
    }
  };

  const handleCrossImgClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    closeGallery();
    handleDelete();
  };

  const handleDeleteImage = () => {
    setIsLoading(true);
    const response = TrainzaApi.DeleteNewsImage(
      isAuthenticated,
      eventId,
      deleteIndex
    );
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("IMG_DELETED"), "success");
          handleGetEventDetail();
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleUpdateGallery = () => {
    const sequence = uploadedImg?.map((item, index) => ({
      id: item.id,
      sequence: index + 1,
    }));

    const PostData = new FormData();

    PostData.append("gallerySequence", JSON.stringify(sequence));

    setIsLoading(true);

    TrainzaApi.UpdateImageGalleryOfNews(
      isAuthenticated,
      PostData,
      eventId
    ).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("GALLERY_UPDATED"), "success");
          handleGetEventDetail();
          setBinaryImg(null);
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };
  // Function to get image dimensions
  function getImageDimensions(imageItem) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const newImage = { ...imageItem, width: width, height: height };
        resolve(newImage);
      };
      img.onerror = () => reject("Failed to load image");
      img.src = imageItem.image;
    });
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.detailHeading}>
        <p>{capitalizeFirstLetter(eventTitle)}</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.selectImgBox}>
        <p>{t("IMG_GALLERY")}</p>
        <h1 style={{ marginTop: "-10px" }}>
          {t("Add multiple images to this Event")}
        </h1>

        <input
          id="imageInput"
          type="file"
          multiple
          accept=".jpg, .jpeg, .gif, .png, .webp"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />

        <CustomButton
          variant="footerBtnCustom"
          type="button"
          opacity={uploadedImg?.length >= 12 ? CONSTANT.OPACITY_RATIO : ""}
          onClick={() => {
            if (uploadedImg?.length < 12) {
              document.getElementById("imageInput").click();
            }
          }}
        >
          <p>
            {t("SELECT")} <span>{t("IMAGE")}</span>
          </p>
        </CustomButton>
      </div>

      <div onDragOver={handleDragOver} className={styles.showImg}>
        <Gallery
          options={{
            bgOpacity: 999,
            showHideOpacity: true,
            scaleMode: "fit",
          }}
        >
          {uploadedImg?.map((item, index) => (
            <Item
              key={index}
              original={item?.image}
              // thumbnail={item?.image}
              width={item.width}
              height={item.height}
            >
              {({ ref, open }) => (
                <a ref={ref} onClick={open} style={{ position: "relative" }}>
                  <img
                    alt={`img ${index + 1}`}
                    src={item?.image}
                    className={styles.imgBox}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDrop={(e) => handleDrop(e, index)}
                  />
                  <img
                    src={CrossImg}
                    alt="CrossImg"
                    className={styles.CrossImg}
                    onClick={(e) => {
                      handleCrossImgClick(e, index);
                      setDeleteIndex(item.id);
                    }}
                  />
                </a>
              )}
            </Item>
          ))}
        </Gallery>
      </div>

      <div className={styles.footer}>
        <p>
          {uploadedImg?.length}{" "}
          <span>{modifySingularPlural(t("IMAGE"), uploadedImg?.length)}</span>
        </p>

        {uploadedImg?.length > 1 && (
          <CustomButton
            variant="footerBtnCustom"
            onClick={() => {
              if (isUpdateSeq) {
                handleUpdateGallery();
              }
            }}
            opacity={isUpdateSeq ? "1" : CONSTANT.OPACITY_RATIO}
            type="button"
          >
            <p>
              {t("UPDATE")} <span>{t("SEQUENCE")}</span>
            </p>
          </CustomButton>
        )}
      </div>

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteImage}
        isLoading={isLoading}
      />
    </div>
  );
}

export default NewsGallery;
