import React, { useEffect, useState } from "react";
import styles from "./EventList.module.css";
import EventThumbnail from "../../../Assests/Images/EventThumbnail.png";
import Cross from "../../../Assests/Images/Cross.svg";
import Edit from "../../../Assests/Images/Edit.svg";
import {
  CustomButton,
  CustomImgButton,
} from "../../../ReactBootstrap/CustomTabButton";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Common/Loader/Loader";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../../Utils/CommonFunction";
import { CONSTANT } from "../../../Utils/Constant";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import DeleteResult from "../../Members/MemberDetail/DeleteResult";
import { DAYS_OF_WEEK, REPEATING_DAYS } from "../../../Utils/MockData";

function EventList({ searchKeyword, setSearchKeyword, setShowtab }) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [eventList, setEventList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [totalCount, setTotalCount] = useState();

  const [orderBy, setOrderBy] = useState("1");

  const [showDelete, setShowDelete] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  }

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const escapedKeyWord = escapeRegExp(keyWord);
      const parts = paragraph?.split(new RegExp(`(${escapedKeyWord})`, "gi"));
      return parts?.map((part, index) =>
        part?.toLowerCase() === keyWord?.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "500",
              fontSize: "15px",
              letterSpacing: "0.3px",
              background: "#CEF56E",
              margin: "0px",
              color: "#000000",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  const handleGetEvent = (page, search, order) => {
    setIsLoading(true);
    const response = TrainzaApi.GetEvent(isAuthenticated, page, search, order);

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (page == 1) {
          setEventList(response?.data?.eventList);
        } else {
          setEventList([...eventList, ...response?.data?.eventList]);
        }
        setPageNumber(page + 1);
        setIsLoadMore(response?.data?.loadMore);
        setTotalCount(response?.data?.count);
      }
    });
  };

  useEffect(() => {
    handleGetEvent(1, searchKeyword, orderBy);
  }, [searchKeyword, orderBy]);

  const handleDeleteGroup = () => {
    const response = TrainzaApi.DeleteEvent(isAuthenticated, deleteIndex);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("EVENT_DELETED"), "success");
          handleGetEvent(CONSTANT.PAGE_ONE, "", orderBy);
          setSearchKeyword("");
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  
  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.sortBy}>
        <p style={{ display: "flex" }}>
          {t("LIST_BY")} -{" "}
          <span
            style={{ cursor: "pointer" }}
            className={
              orderBy === "1" ? styles.OrderByDateFilter : styles.OrderByDate
            }
            onClick={() => {
              setOrderBy("1");
            }}
          >
            {" "}
            {t("DATE_ORDER")}{" "}
          </span>{" "}
          -{" "}
          <p
            style={{ cursor: "pointer", margin: "0px 2px" }}
            className={orderBy === "2" ? styles.OrderByDateFilter : ""}
            onClick={() => {
              setOrderBy("2");
            }}
          >
            {" "}
            {t("ALPHABETICAL")}
          </p>
        </p>
      </div>

      <div className={styles.devider}></div>

      {eventList?.map((item, index) => {
        return (
          <div
            className={styles.eventListBox}
            key={index}
            style={{
              background:
                item?.status === CONSTANT.INACTIVE
                  ? CONSTANT.INACTIVE_COLOR
                  : "",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.eventImg ? item.eventImg : EventThumbnail}
                alt="EventThumbnail"
                className={styles.imageSection}
                // onClick={() => {
                //   navigate("/events/event-detail", {
                //     state: {
                //       event_id: item.id,
                //       event_title: item.title,
                //       event_img: item.eventImg,
                //     },
                //   });
                // }}
                onClick={() => {
                  navigate("/events/event-detail", {
                    state: {
                      event_id: item.id,
                      event_title: item.title,
                      event_img: item.eventImg,
                      is_update: true,
                    },
                  });
                }}
              ></img>
              <div className={styles.detail}>
                <p
                  // onClick={() => {
                  //   navigate("/events/event-detail", {
                  //     state: {
                  //       event_id: item.id,
                  //       event_title: item.title,
                  //       event_img: item.eventImg,
                  //       is_update: true,
                  //     },
                  //   });
                  // }}
                  onClick={() => {
                    navigate("/events/event-detail", {
                      state: {
                        event_id: item.id,
                        event_title: item.title,
                        event_img: item.eventImg,
                        is_update: true,
                      },
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {
                    <HighlightKeyInParagraph
                      keyWord={searchKeyword}
                      paragraph={capitalizeFirstLetter(item?.title)}
                    />
                  }
                </p>
                {item.distance?.length !== 0 ? (
                  <h1>
                    {item.distance?.map((dis, index) => {
                      return (
                        <React.Fragment key={index}>
                          {dis.distance}
                          <span>{t("KM")}</span>{" "}
                          {item.distance?.length - 1 !== index ? "|" : ""}{" "}
                        </React.Fragment>
                      );
                    })}
                  </h1>
                ) : (
                  <h1>----</h1>
                )}

                <h2>
                  {" "}
                  {item.startDate
                    ? formatDate(item.startDate)
                    : REPEATING_DAYS[item.repeatingDays - 1].day}{" "}
                  {item.endDate ? `- ${formatDate(item.endDate)}` : ""}
                </h2>
              </div>
            </div>

            <div className={styles.editCross}>
              <CustomImgButton
                variant="primary"
                src={Cross} // Replace with your icon path
                alt="cross"
                onClick={() => {
                  handleDelete();
                  setDeleteIndex(item?.id);
                }}
              ></CustomImgButton>

              <CustomImgButton
                variant="primary"
                src={Edit} // Replace with your icon path
                alt="edit"
                // onClick={() => {
                //   navigate("/events/events-update", { state: { id: item.id } });
                // }}
                onClick={() => {
                  navigate("/events/event-detail", {
                    state: {
                      event_id: item.id,
                      event_title: item.title,
                      event_img: item.eventImg,
                      is_update: true,
                    },
                  });
                }}
              ></CustomImgButton>
            </div>
          </div>
        );
      })}

      {eventList?.length === 0 && searchKeyword !== "" ? (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      ) : (
        ""
      )}

      {eventList?.length === 0 && (searchKeyword === "") & !isLoading ? (
        <div>
          <div className={styles.noActiveGrp}>
            <p>
              {t("YOU_CUIRRENTLY_HAVE_NO_EVENTS")} -{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowtab(3);
                }}
              >
                {t("ADD_AN_EVENT")}
              </span>
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.inviteormore}>
        <div className={styles.membersCount}>
          <CustomButton
            variant="footerBtnCustom"
            onClick={() => {
              setShowtab(3);
            }}
          >
            <p>
              {t("ADD")} <span>{t("EVENT")}</span>
            </p>
          </CustomButton>

          <p>
            {totalCount}{" "}
            <span>{totalCount > 1 ? t("Events") : t("Event")}</span>
          </p>
          <CustomButton
            variant="footerBtnCustom"
            opacity={isLoadMore ? "1" : CONSTANT.OPACITY_RATIO}
            onClick={() => {
              if (isLoadMore) {
                handleGetEvent(pageNumber, searchKeyword, orderBy);
              }
            }}
          >
            <p>
              {t("LOAD")} <span>{t("MORE")}</span>
            </p>
          </CustomButton>
        </div>
      </div>

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteGroup}
        isLoading={isLoading}
      />
    </div>
  );
}

export default EventList;
