import { useTranslation } from "react-i18next";
import styles from "./AddRoutes.module.css";
import { useController } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useState, useRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import CrossImg from "../../../Assests/Images/Cross.svg";
import TextEditor from "../../../Common/TextEditor/TextEditor";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RouteLink from "../../../Assests/Images/routeLink.png";
import UploadImage from "../../../Assests/Images/uploadImage.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import TrainzaApi from "../../../Helpers/Api";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Loader from "../../../Common/Loader/Loader";
import DeleteRoute from "../DeleteRoute";
import { CONSTANT } from "../../../Utils/Constant";

function AddRoutes({
  id,
  setShowtab,
  onClose,
  setCurrentEditData,
  addManually,
  setAddManually,
  setRouteId,
  setRouteType,
  routeList,
  setWhichScreen,
  handleGetRouteDetail123,
  setSearchKeyword,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ROUTE_DETAIL: "",
    },
  });

  const { t } = useTranslation();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "ROUTE_DETAIL",
    control,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [idForUpdate, setIdForUpdate] = useState();
  const [routeImg, setRouteImg] = useState(null);
  const [binaryRouteImg, setBinaryRouteImg] = useState(null);
  const [distanceUnit, setDistanceUnit] = useState("KM");
  const [isActive, setIsActive] = useState(0);

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  // Button selected distance unit
  const handleButtonClick = (unit) => {
    setDistanceUnit(unit);
  };

  // Select the image to upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setBinaryRouteImg(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setRouteImg(fileUrl);
    } else {
      setRouteImg(null);
    }
  };

  // Manage the error for distance
  const handleHoursChange = (e) => {
    const value = e.target.value;

    // Check if the value contains a decimal point
    if (value.includes(".") || value > 99) {
      // Prevent setting state or displaying an error for decimal values
      return;
    }

    // Ensure only integer values are set
    const intValue = parseInt(value, 10);

    // Update state
    setHours(intValue);

    // Handle error message based on conditions
    if (intValue == 0 || intValue == "" || !intValue) {
      setErrorMessage(t("ERROR_DISTANCE"));
    } else {
      setErrorMessage("");
    }
  };

  const handleMinutesChange = (e) => {
    const value = e.target.value;

    // Check if the value contains a decimal point
    if (value.includes(".") || value > 99) {
      // Prevent setting state for decimal values
      return;
    }

    // Ensure only integer values are set
    const intValue = parseInt(value, 10);

    // Update state
    setMinutes(intValue);

    // Handle error message based on conditions
    if (intValue == 0 || intValue == "" || !intValue) {
      setErrorMessage(t("ERROR_DISTANCE"));
    } else {
      setErrorMessage("");
    }
  };

  const ScrollRef = useRef(null);

  // Add route api call
  function OnSubmits(data) {
    if (isSubmitting) return;

    setIsLoading(true);
    if (
      (hours == 0 || hours == "" || !hours) &&
      (minutes == 0 || minutes == "" || !minutes)
    ) {
      setErrorMessage(t("ERROR_DISTANCE"));
      setIsLoading(false);

      return; // Prevent form submission
    }

    setIsSubmitting(true);
    const PostData = new FormData();
    if (idForUpdate) {
      PostData.append("routeId", idForUpdate);
    }

    PostData.append("name", data.ROUTENAME);
    PostData.append("details", data.ROUTE_DETAIL);
    PostData.append("routeLink", data.ROUTE_Link ? data.ROUTE_Link : "");
    PostData.append("image", binaryRouteImg ? binaryRouteImg : "");
    const distance = `${hours ? hours : 0}.${minutes ? minutes : 0}`;
    PostData.append("distance", distance);

    PostData.append("distanceUnit", distanceUnit === "KM" ? 1 : 2);
    PostData.append("status", isActive ? 1 : 0);

    TrainzaApi.AddRouteApi(isAuthenticated, PostData)
      .then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            if (idForUpdate) {
              Toster(t("ROUTE_UPDATED_SUCCESSFULLY"), "success");
              onClose();
              setSearchKeyword("");
            } else {
              Toster(t("ROUTE_ADDED_SUCCESSFULLY"), "success");
              setSearchKeyword("");
              if (addManually) {
                handleGetRouteDetail123(response?.data?.id);
                const newObj = {
                  id: response?.data?.id,
                  name: data.ROUTENAME,
                  distance: distance,
                  distanceUnit: distanceUnit === "KM" ? 1 : 2,
                };
                setWhichScreen(CONSTANT.GET_DETAIL);
                routeList.push(newObj);
                setAddManually(false);
                setRouteId(response?.data?.id);
                setRouteType(response?.data?.id);
              } else {
                if (id) {
                  navigate("/routes");
                } else {
                  setShowtab(1);
                }
              }
            }
          } else if (response?.data?.code === STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      })
      .finally(() => {
        setIsSubmitting(false); // Reset loading state after response is received
      });
  }

  // Route details api
  const handleGetRouteDetail = () => {
    setIsLoading(true);
    const response = TrainzaApi.GetRoutesDetail(isAuthenticated, id);

    response.then((response) => {
      const RouteInfo = response?.data;

      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setValue("ROUTENAME", RouteInfo?.name);
        setValue("ROUTE_DETAIL", RouteInfo?.details);
        setRouteImg(RouteInfo?.image);
        setValue("ROUTE_Link", RouteInfo?.routeLink);
        setIsActive(RouteInfo?.status);
        const hours = Math.floor(RouteInfo.distance);
        const minutes = RouteInfo?.distance?.toString().split(".")[1];

        setHours(hours);
        setMinutes(minutes);
        setDistanceUnit(RouteInfo.distanceUnit == 1 ? "KM" : "MI");
      }
    });
  };

  useEffect(() => {
    if (id) {
      handleGetRouteDetail();
    }
  }, [id]);

  useEffect(() => {
    setIdForUpdate(id);
  }, [id]);

  function HandleChangeStatus() {}

  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  function removeLeadingZeros(input) {
    // Convert input to a string if it's a number
    const inputStr = typeof input === "number" ? input.toString() : input;

    // Remove leading zeros
    const result = parseInt(inputStr, 10).toString();

    // Handle edge case where the input might be zero
    return result === "NaN" ? "0" : result;
  }

  const cleanPastedContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.body.querySelectorAll("*");

    elements.forEach((el) => {
      el.style.color = "";
    });

    return doc.body.innerHTML;
  };

  const handleChange = (value, delta, source, editor) => {
    const sanitizedValue = value.replace(
      /^(<p>(&nbsp;|\s)*<\/p>)*|^(&nbsp;|\s)*/,
      ""
    );
    if (!sanitizedValue.trim()) {
      setError("ROUTE_DETAIL", {
        type: "manual",
        message: "",
      });
    } else {
      clearErrors("ROUTE_DETAIL");
      onChange(sanitizedValue);
    }

    if (source === "user") {
      const cleanContent = cleanPastedContent(sanitizedValue);
      onChange(cleanContent);
    } else {
      onChange(sanitizedValue);
    }
    //  // Apply max character limit
    //  if (sanitizedValue.length > 65535) {
    //   // Trim the value if it exceeds the limit
    //   onChange(sanitizedValue.slice(0, 65535));
    // } else {
    //   onChange(sanitizedValue);
    // }
  };

  return (
    <div className={styles.addSection} ref={ScrollRef}>
      {isLoading ? <Loader /> : ""}
      <Form onSubmit={handleSubmit(OnSubmits)}>
        {/* Route name input */}
        <div className={styles.eventTitle}>
          <Form.Group className={styles.inputIcon}>
            <Form.Label className={styles.clublabelText}>
              {t("ROUTE__")}
              <span> {t("NAME")}</span>
            </Form.Label>
            <Form.Control
              className={styles.FormInput}
              type="text"
              placeholder={t("NAME_OF_ROUTE")}
              {...register("ROUTENAME", {
                ...ValidationSchema.ROUTENAME,
                onChange: (e) => {
                  handleKeyPress(e);
                },
              })}
              isInvalid={!!formState.errors.ROUTENAME}
              autoFocus={true}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.ROUTENAME?.message)}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* Route detail input */}
        <div className={styles.infoLabel}>
          <div className="editorBox">
            <h2 className={styles.clublabelText}>
              {t("ROUTE__")} <span>{t("DETAILS")}</span>{" "}
              {t("OPTIONAL_ROUTE_INFO")}
            </h2>

            <TextEditor
              placeholder={t("DETAIL_ROUTE_PLACEHOLDER")}
              value={value}
              onChange={handleChange}
              onBlur={onBlur}
              ref={ref}
              error={error}
              isInvalid={formState.errors.ROUTE_DETAIL}
              autoFocus={true}
            />
          </div>
        </div>

        {/* Route link input */}
        <div className={styles.eventImage}>
          <div className={styles.routeHeading}>
            <img src={RouteLink} alt="Route Link" />
            <h4>
              {t("ROUTE__")} <span>{t("LINK")}</span> {t("OPTIONA")}
            </h4>
          </div>
          <p>{t("ROUTE_LINK_DETAIL")}</p>

          <Form.Group className={styles.inputIcon}>
            <Form.Label className={styles.labelText}>
              <span>{t("LINK_TO_ROUTE")}</span> {t("ROUTE_LINK_LABEL")}
            </Form.Label>
            <Form.Control
              className={styles.FormInput}
              type="text"
              placeholder={t("LINK_PLACEHOLDER")}
              {...register("ROUTE_Link", {
                ...ValidationSchema.ROUTE_Link,
                onChange: (e) => {
                  handleKeyPress(e);
                },
              })}
              isInvalid={!!formState.errors.ROUTE_Link}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.ROUTE_Link?.message)}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        {/* image upload button */}
        <div className={styles.eventImage}>
          <div className={styles.routeHeading}>
            <img src={UploadImage} alt="Route Info" />
            <h4>
              {t("ROUTE__")} <span>{t("INFO")}</span> {t("OPTIONA")}
            </h4>
          </div>
          <p>{t("ROUTE_INFO_DETAIL")}</p>
          <div className={styles.uploadBox}>
            <div className={styles.profileSectionOption}>
              <label htmlFor="file-input" className={styles.customFileInput}>
                <CustomButton variant="footerBtnCustom" as="span">
                  <p>
                    {t("UPLOAD")} <span>{t("IMAGE")}</span>
                  </p>
                </CustomButton>
              </label>
              <input
                type="file"
                id="file-input"
                accept=".jpg, .jpeg, .gif, .png, .webp"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
            {routeImg && (
              <div className={styles.previewMembershipImgBox}>
                <img
                  src={routeImg}
                  alt="previewMembershipImg"
                  className={styles.previewImg}
                ></img>

                <img
                  src={CrossImg}
                  alt="CrossImg"
                  className={styles.removeImg}
                  onClick={() => setRouteImg(null)}
                ></img>
              </div>
            )}
          </div>
        </div>

        {/* Route distance input */}
        <div className={styles.routeDistance}>
          <h4>
            {t("ROUTE__")} <span>{t("DISTANCE")}</span> ({t("REQUIRED_")})
          </h4>
          <p>{t("ROUTE_DIS_DETAIL")}</p>
        </div>

        <div className={styles.distanceSection}>
          <div className={styles.distanceData}>
            <h3>{t("DISTANCE")}</h3>
            <div className={styles.Distance}>
              <div className={styles.timeInput}>
                <input
                   type="number"
                  name="hours"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={hours}
                  onChange={handleHoursChange}
                  onKeyPress={(event) => {
                    // Prevent the user from typing a dot (.) in the input
                    if (
                      event.key === "." ||
                      event.key === "e" ||
                      event.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  max="99"
                />
              </div>
              <p className={styles.dot}>.</p>
              <div className={styles.timeInput}>
                <input
                 type="number"
                  name="minutes"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={removeLeadingZeros(minutes)}
                  onChange={handleMinutesChange}
                  onKeyPress={(event) => {
                    // Prevent the user from typing a dot (.) in the input
                    if (
                      event.key === "." ||
                      event.key === "e" ||
                      event.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <h6>{distanceUnit}</h6>
            </div>
            <div className="switch-field">
              <Form.Check
                type="radio"
                id="radio-one"
                name="switch-one"
                label={t("KILOMETER")}
                checked={distanceUnit === "KM"}
                onClick={() => handleButtonClick("KM")}
              />
              <Form.Check
                type="radio"
                id="radio-two"
                name="switch-one"
                label={t("MILES")}
                checked={distanceUnit === "MI"}
                onClick={() => handleButtonClick("MI")}
              />
            </div>
          </div>
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
        </div>

        {/* Route active inactive button */}
        <div className={styles.activeEventSection}>
          <div className={styles.switchOn}>
            <h3>{t("ACTIVE_INACTIVE_")}</h3>
            <p>{t("ACTIVE_DETAIL")}</p>
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            className={styles.switchButton}
            checked={isActive}
            onChange={() => {
              setIsActive(!isActive);
              HandleChangeStatus(!isActive, idForUpdate);
            }}
          />
        </div>

        <div className={styles.eventBtn}>
          <div className="d-flex">
            <div className={styles.cancel}>
              <CustomButton
                variant="cancelBtn"
                type="button"
                onClick={() => {
                  if (addManually) {
                    setAddManually(false);
                    setWhichScreen(CONSTANT.SELECT_THROUGH_OPTION);
                  } else {
                    if (id) {
                      setShowtab(1);
                      navigate("/routes");
                      setCurrentEditData();
                    } else {
                      setShowtab(1);
                      navigate("/routes");
                      setCurrentEditData();
                    }
                  }
                }}
              >
                {t("CENCEL")}
              </CustomButton>
            </div>
            <div className={styles.delete}>
              {idForUpdate ? (
                <CustomButton
                  variant="deleteBtn"
                  type="button"
                  onClick={() => handleShowDelete()}
                >
                  <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                  {t("DELETE")}
                </CustomButton>
              ) : (
                <CustomButton
                  variant="deleteBtn"
                  type="button"
                  disabled={true}
                  className={styles.disabledButton}
                >
                  <RiDeleteBin6Line size="20" style={{ marginRight: "5px" }} />
                  {t("DELETE")}
                </CustomButton>
              )}
            </div>
          </div>
          <div className={styles.save}>
            <CustomButton
              variant="footerBtnCustom"
              onClick={handleSubmit(OnSubmits)}
              disabled={isSubmitting}
              type="button"
            >
              <p>
                <>
                  {idForUpdate ? (
                    <>
                      {t("SAVE")} <span>{t("ROUTE")}</span>
                    </>
                  ) : (
                    <span>{t("SAVE")}</span>
                  )}
                </>
              </p>
            </CustomButton>
          </div>
        </div>
      </Form>
      <DeleteRoute
        handleClose={handleCloseDelete}
        show={showDelete}
        RouteId={id}
        setShowDelete={setShowDelete}
        setShowtab={setShowtab}
        isEdit={true}
      />
    </div>
  );
}

export default AddRoutes;
