import React, { useEffect, useRef, useState } from "react";
import styles from "./AddWorkout.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdTime } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { CONSTANT } from "../../../Utils/Constant";
import WorkoutRequired from "./WorkoutRequired";
import RouteOptional from "./RouteOptional";
import { CustomButton } from "../../../ReactBootstrap/CustomTabButton";
import { useForm } from "react-hook-form";
import TrainzaApi from "../../../Helpers/Api";
import { useSelector } from "react-redux";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import Toster from "../../../Common/Toster/Toster";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import DeleteWorkoutTypes from "./DeleteWorkoutType";
import Loader from "../../../Common/Loader/Loader";
import Calender from "../../../Assests/Images/Calender.svg";
import CalenderComp from "../../Members/MemberDetail/CalenderComp";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "../../../Utils/MockData";
import { MONTH_CODE, MONTH_NO_CODE } from "../../../Utils/MonthCode";
import { correctFormat, fetchImageAsFile } from "../../../Utils/CommonFunction";
import TraningCalender from "./TraningCalender";

function AddTrainingDateTime({
  setAddTraning,
  searchKeyword,
  setTraningId,
  traningId,
  workOutId,
  setWorkOutId,
  trainingObj,
  setTrainingObj,
  idForUpdate,
  setIdForUpdate,
}) {
  const isAuthenticated = useSelector(
    (state) => state.user.userTokenTrainzaBrand
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [workoutDetail, setWorkoutDetail] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isAddManually, setIsAddManually] = useState(false);
  const [isAddManuallyRoute, setIsAddManuallyRoute] = useState(false);
  const [whichScreenWork, setWhichScreenWork] = useState(1);
  const [whichScreenRoute, setWhichScreenRoute] = useState(
    CONSTANT.SELECT_THROUGH_OPTION
  );
  const [routeDetail, setRouteDetail] = useState([]);
  const [binaryRouteImg, setBinaryRouteImg] = useState(null);
  const [removedImg, setRemovedImg] = useState([]);
  const [removedImgId, setRemovedImgId] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [isAnyTime, setIsAnyTime] = useState(0);
  const [grpList, setGrpList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [workoutList, setWorkoutList] = useState([]);
  const [workoutType, setWorkoutType] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [routeType, setRouteType] = useState();
  const [showDeleteTraning, setShowDeleteTraning] = useState(false);
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [showDate, setShowDate] = useState(false);
  const currentDate = new Date();

  useEffect(() => {
    setWeekday(DAYS_OF_WEEK[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(MONTH_OF_YEAR[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  useEffect(() => {
    var daySelected = day;
    if (day.toString()?.length < 2) {
      daySelected = `${0}${day}`;
    } else {
      daySelected = day;
    }
    const rightFormatOfDate = `${year}-${MONTH_CODE[month]}-${daySelected}`;
    if (rightFormatOfDate) {
      setSelectedDate(rightFormatOfDate);
    }
  }, [day, year, month, weekday]);

  const handleShowDate = () => setShowDate(true);
  const handleCloseDate = () => setShowDate(false);
  var tileDisabledForStartDate = ({ date, view }) => {
    const minDate = new Date().setHours(0, 0, 0, 0);
    return date < minDate;
  };

  useEffect(() => {
    if (workOutId) {
      setWorkoutType(workOutId);
    }
  }, [workOutId]);

  useEffect(() => {
    if (routeType) {
      setRouteType(routeType);
    }
  }, [routeType]);

  //   function for handle validation
  const { register, reset, setValue } = useForm({
    mode: "onChange",
  });

  //====== function for calling group lisyt api======
  const handleGetGroupList = (search) => {
    const response = TrainzaApi.groupList(isAuthenticated, search);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setGrpList(response?.data?.groupList);
      }
    });
  };

  useEffect(() => {
    handleGetGroupList(searchKeyword);
  }, [searchKeyword]);

  const handleCheckboxChange = (groupId) => {
    setSelectedGroups([groupId]);
  };

  //===== function for calling workout  list api====
  const handleWorkoutList = (page, search, skipPaging = 1) => {
    const response = TrainzaApi.getWorkoutList(
      isAuthenticated,
      page,
      search,
      (skipPaging = skipPaging)
    );
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const activeObject = response?.data?.workoutList?.filter(
          (item) => item.status === 1
        );
        setWorkoutList(activeObject);
      }
    });
  };

  useEffect(() => {
    if (!searchKeyword) {
      handleWorkoutList(1, "");
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (searchKeyword) {
      handleWorkoutList(1, searchKeyword, 1);
    }
  }, [searchKeyword]);

  //===== route list api======
  const handleGetRoute = (page, search,  skipPaging = 1) => {
    setIsLoading(true);
    const response = TrainzaApi.GetRoutes(
      isAuthenticated,
      page,
      search,
      "",
      (skipPaging = skipPaging)
    );

    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (page == 1) {
          const activeObject = response?.data?.routeList?.filter(
            (item) => item.status === 1
          );
          setRouteList(activeObject);
        } else {
          const activeObject = response?.data?.routeList?.filter(
            (item) => item.status === 1
          );
          setRouteList([...routeList, ...activeObject]);
        }
      }
    });
  };

  useEffect(() => {
    handleGetRoute(1, searchKeyword);
  }, [searchKeyword]);

  // =====function for select workout type======
  const handleWorkout = (e) => {
    setValue("WORKOUT_TYPES", e.target.value);
    setWorkoutType(e.target.value);
  };

  // =====function for select route type======
  const handleRoute = (e) => {
    setValue("ROUTES_TYPES", e.target.value);
    setRouteType(e.target.value);
  };

  function determineDataType(data) {
    if (typeof data === "string") {
      return "string";
    }

    if (
      data instanceof ArrayBuffer ||
      data instanceof Blob ||
      data instanceof Uint8Array
    ) {
      return "binary";
    }

    return "unknown";
  }

  const getFile = async () => {
    const isBinary = determineDataType(binaryRouteImg);
    setIsLoading(true);
    if (isBinary !== "binary") {
      var path = binaryRouteImg;
      const startsWithHtt = path.startsWith("htt");
      if (startsWithHtt) {
        path = `${process.env.REACT_APP_BASE_URL}/proxy?url=${binaryRouteImg}`;
      } else {
        path = binaryRouteImg;
      }
      try {
        const imageFile = await fetchImageAsFile(path, "123.jpg");

        setBinaryRouteImg(imageFile);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    } else {
      setBinaryRouteImg(binaryRouteImg);
      setIsLoading(false);
    }
  };

  const fetchImageAsBinary = async (url) => {
    var path = url;
    const startsWithHtt = path.startsWith("htt");
    if (startsWithHtt) {
      path = `${process.env.REACT_APP_BASE_URL}/proxy?url=${url}`;
    } else {
      path = url;
    }
    try {
      const imageFile = await fetchImageAsFile(path, "myImage.jpg");

      return imageFile;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (routeDetail?.image) {
      getFile();
    }
  }, [binaryRouteImg, routeDetail]);

  // ======  function for calling save workout =====
  const OnSubmit = async (formdata) => {
    setIsLoading(true);

    if (workoutDetail.typeId && selectedGroups?.length < 1) {
      Toster("Please add a group", "error");
      setIsLoading(false);
    }

    if (
      !isAnyTime &&
      !startTime &&
      selectedGroups?.length > 0 &&
      workoutDetail.typeId
    ) {
      Toster("Please select time", "error");
      setIsLoading(false);
    }

    const PostData = new FormData();
    if (idForUpdate) {
      PostData.append("trainingId", idForUpdate);
    }
    PostData.append("trainingDate", selectedDate);
    PostData.append(
      "trainingTime",
      startTime ? format(new Date(startTime), "HH:mm:ss") : ""
    );

    PostData.append("isAnytime", isAnyTime ? 1 : 0);
    PostData.append("trainingGroups", JSON.stringify(selectedGroups));

    // WORKOUT RELATED APPEND DATA
    PostData.append(
      "workoutTypeId",
      workoutDetail.typeId ? workoutDetail.typeId : ""
    );
    PostData.append(
      "workoutDetails",
      workoutDetail.details ? workoutDetail.details : ""
    );

    // IMAGES WE SEND IN WORKOUT

    for (var i = 0; i < selectedImage?.length; i++) {
      var isSelectedImgBInary = determineDataType(selectedImage[i]);

      if (isSelectedImgBInary !== "binary") {
        const imageFile = await fetchImageAsBinary(selectedImage[i]);
        PostData.append("workoutImage", imageFile);
      } else {
        PostData.append("workoutImage", selectedImage[i]);
      }
    }

    PostData.append(
      "workoutVideoLink",
      workoutDetail.videoLink ? workoutDetail.videoLink : ""
    );

    // ROUTE RELATED APPEND DATA
    if (routeDetail?.name && whichScreenRoute === 2) {
      PostData.append("isRouteAdded", 1);
    } else {
      PostData.append("isRouteAdded", 0);
    }

    PostData.append("routeName", routeDetail?.name ? routeDetail?.name : "");
    PostData.append(
      "routeDetails",
      routeDetail?.details ? routeDetail?.details : ""
    );
    PostData.append(
      "routeLink",
      routeDetail?.routeLink ? routeDetail?.routeLink : ""
    );

    PostData.append("routeImage", binaryRouteImg ? binaryRouteImg : "");

    PostData.append("removeRouteImage", binaryRouteImg ? 0 : 1);
    PostData.append(
      "routeDistance",
      routeDetail?.distance ? routeDetail?.distance : 0
    );

    if (routeDetail?.name) {
      PostData.append("routeDistanceUnit", routeDetail?.distanceUnit);
    } else {
      PostData.append("routeDistanceUnit", "");
    }

    if (!workoutDetail.typeId && !isAddManually && !isAddManuallyRoute) {
      Toster("Please add workout", "error");
      setIsLoading(false);
    }

    if (
      workoutDetail.typeId &&
      !isAddManually &&
      !isAddManuallyRoute &&
      selectedGroups?.length > 0 &&
      (isAnyTime || startTime)
    ) {
      TrainzaApi.addTraning(isAuthenticated, PostData).then((response) => {
        setIsLoading(false);
        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            setAddTraning(false);
            setTraningId("");
            handleWorkoutList("");
            setSelectedGroups([]);
            setWorkoutType("");
            setRouteType("");
            setTrainingObj();

            setRouteDetail([]);
            setWorkoutDetail([]);
            setIdForUpdate("");
            setIsAnyTime();
            setStartTime();
            setSelectedGroups([]);

            if (idForUpdate) {
              Toster(t("TRANING_UPDATED_SUCCUSFULLY"), "success");
            } else {
              Toster(t("TRANING_ADD_SUCCUSFULLY"), "success");
            }
          } else if (response?.data?.code === STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  //======   function for delete workout list======
  const handleDeleteTraning = () => {
    const response = TrainzaApi.deleteTraningList(isAuthenticated, traningId);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else if (response?.code === STATUS_CODE.SUCCESS) {
        Toster(t("WORKOUT_DELETE_SUCCESSFULLY"), "success");
        // handleTrainingList(searchKeyword, "");
        setAddTraning(false);
        setShowDeleteTraning(false);
        if (trainingObj) {
          setTrainingObj();
        }
      } else if (
        response?.data.code ===
        STATUS_CODE.THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION
      ) {
        Toster(
          t(
            "THE_WORKOUT_TYPE_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION"
          ),
          "error"
        );
        setShowDeleteTraning(false);
      } else {
        setShowDeleteTraning(false);
      }
    });
  };

  // Handling Anytime Checkbox
  const handleAnytimeCheckbox = (event) => {
    const isChecked = event.target.checked;
    setIsAnyTime(isChecked ? 1 : 0);
    if (isChecked) {
      setStartTime(null);
    }
  };

  // Handling Time Selection
  const handleTimeChange = (time) => {
    setStartTime(time);
    if (time) {
      setIsAnyTime(0);
    }
  };

  useEffect(() => {
    if (trainingObj) {
      const nameToFind = trainingObj?.groups[0].name;

      const matchingIds = grpList
        ?.filter((item) => item.name === nameToFind)
        .map((item) => item.id);

      setSelectedGroups(matchingIds);

      setIsAnyTime(trainingObj?.isAnytime);

      setYear(trainingObj?.trainingDate?.substring(0, 4));
      setMonth(MONTH_NO_CODE[trainingObj?.trainingDate?.substring(5, 7)]);
      setDay(trainingObj?.trainingDate?.substring(8, 10));

      setSelectedDate(trainingObj?.trainingDate);
      setStartDate(trainingObj?.trainingDate);

      if (trainingObj?.trainingTime) {
        const defaultStartTime = new Date(
          `Mon Jun 10 2024 ${trainingObj?.trainingTime} GMT+0530 (India Standard Time)`
        );
        setStartTime(defaultStartTime);
      }

      setWorkoutType(trainingObj.workoutId);
      setWorkOutId(trainingObj?.workoutId);

      setRouteType(trainingObj?.routesId);
    }
  }, [trainingObj, grpList]);

  const handlegetTrainingDetail = () => {
    const response = TrainzaApi.getTrainingDetail(isAuthenticated, idForUpdate);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setImgData(response?.data?.workoutImage);
        setWorkoutDetail({
          typeId: response?.data?.workoutTypeId,
          details: response?.data?.workoutDetails,
          videoLink: response?.data?.workoutVideoLink,
          status: 0,
          images: response?.data?.workoutImage,
        });

        setRouteDetail({
          name: response?.data?.routeName,
          details: response?.data?.routeDetails,
          routeLink: response?.data?.routeLink,
          distance: response?.data?.routeDistance,
          distanceUnit: Number(response?.data?.routeDistanceUnit),
          status: 0,
          image: response?.data?.routeImage,
        });

        setBinaryRouteImg(response?.data?.routeImage);

        setSelectedGroups(response?.data?.trainingGroups);

        setIsAnyTime(response?.data?.isAnytime);

        if (response?.data?.trainingTime) {
          const time = new Date(
            `Sat Jul 27 2024 ${response?.data?.trainingTime} GMT+0530 (India Standard Time)`
          );
          setStartTime(time);
        }

        setYear(response?.data?.trainingDate?.substring(0, 4));
        setMonth(MONTH_NO_CODE[response?.data?.trainingDate?.substring(5, 7)]);
        setDay(response?.data?.trainingDate?.substring(8, 10));

        setSelectedDate(response?.data?.trainingDate);
        setStartDate(response?.data?.trainingDate);
      }
    });
  };

  useEffect(() => {
    if (idForUpdate) {
      handlegetTrainingDetail();
    }
  }, [idForUpdate]);

  useEffect(() => {
    const urldata = removedImg;

    const imgCollection = imgData;

    const idArray = [];

    imgCollection?.forEach((item) => {
      if (urldata?.includes(item.image)) {
        idArray?.push(item.id);
      }
    });

    setRemovedImgId(idArray);
  }, [removedImg]);

  const maxTime = new Date();
  maxTime.setHours(23);
  maxTime.setMinutes(59);
  maxTime.setSeconds(0);
  maxTime.setMilliseconds(0);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.headingAddMember}>
        {idForUpdate ? (
          <p>Update training</p>
        ) : (
          <p>
            {t("NEW_GROUP")} <span> {t("ADD_TRAINING")}</span>
          </p>
        )}
      </div>
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>1</p>
        </div>
        <h1>
          {t("TRAING_DATE_TIME_AND_GRP")}
          <span>({t("REQUIRED")})</span>
        </h1>
      </div>
      <Form>
        <div className={styles.dateTimeGroupBox} style={{ marginTop: "-10px" }}>
          <p>
            <span>{t("DATE_AND_TIME")} -</span>{" "}
            {t("SET_TRAINING_DATE_AND_TIME")}
          </p>
          <div className={styles.selectDateAndTime}>
            <div className={styles.datePickerBox}>
              <div className={styles.dateSelection} onClick={handleShowDate}>
                <img src={Calender} alt="calender"></img>
                {showDate ? (
                  <p>{t("SELECT_DATE")}</p>
                ) : (
                  <p>
                    {day} {month} {year}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.timePickerBox}>
              <DatePicker
                {...register("START_TIME")}
                selected={startTime}
                onChange={(time) => handleTimeChange(time)}
                placeholderText={t("SELECT_TIME")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={CONSTANT.TIME_INTERVAL_ONE}
                dateFormat={CONSTANT.TIME_FORMAT}
                className={
                  isAnyTime
                    ? `${styles.disbleDatepicker}`
                    : `${styles.timedatePicker}`
                }
                onFocus={(e) => (e.target.readOnly = true)}
                disabled={isAnyTime}
                minTime={
                  correctFormat(selectedDate) === getCurrentDate()
                    ? new Date()
                    : ""
                }
                maxTime={
                  correctFormat(selectedDate) === getCurrentDate()
                    ? maxTime
                    : ""
                }
              />
              <IoMdTime />
            </div>

            <Form.Check
              type="checkbox"
              id="anytime-checkbox"
              checked={isAnyTime}
              onChange={handleAnytimeCheckbox}
              label={t("ANYTIME")}
              className={styles.checkbox}
            />
          </div>

          <div className={styles.devider}></div>

          <div className={styles.selectTraingBox}>
            <p>
              {t("SELECT")} <span>{t("TRAINING_GROUP")}</span>
            </p>

            {grpList?.map((group, index) => (
              <Form.Check
                key={index}
                className={styles.checboxTraining}
                type={"checkbox"}
                id={`default-checkbox-${index}`}
                label={group.name}
                checked={selectedGroups.includes(group.id)}
                onChange={() => handleCheckboxChange(group.id)}
              />
            ))}
          </div>
        </div>

        <WorkoutRequired
          searchKeyword={searchKeyword}
          workoutList={workoutList}
          setWorkoutType={setWorkoutType}
          handleWorkout={handleWorkout}
          workoutType={workoutType}
          workOutId={workOutId}
          traningId={traningId}
          setWorkOutId={setWorkOutId}
          trainingObj={trainingObj}
          setWorkoutList={setWorkoutList}
          setWorkoutDetail={setWorkoutDetail}
          workoutDetail={workoutDetail}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setIsAddManually={setIsAddManually}
          idForUpdate={idForUpdate}
          setIdForUpdate={setIdForUpdate}
          setRemovedImg={setRemovedImg}
          removedImg={removedImg}
          whichScreen={whichScreenWork}
          setWhichScreen={setWhichScreenWork}
        />
        <RouteOptional
          searchKeyword={searchKeyword}
          routeList={routeList}
          setRouteType={setRouteType}
          handleRoute={handleRoute}
          routeType={routeType}
          traningId={traningId}
          setTraningId={setTraningId}
          routeDetail={routeDetail}
          setRouteDetail={setRouteDetail}
          setBinaryRouteImg={setBinaryRouteImg}
          binaryRouteImg={binaryRouteImg}
          setIsAddManually={setIsAddManuallyRoute}
          idForUpdate={idForUpdate}
          setIdForUpdate={setIdForUpdate}
          whichScreen={whichScreenRoute}
          setWhichScreen={setWhichScreenRoute}
        />
        <div className={styles.saveBox}>
          <CustomButton
            variant={"cancelBtn"}
            type="button"
            onClick={() => {
              setAddTraning(false);
              setTraningId("");
              setTrainingObj();
              setWorkOutId("");
              setWorkoutType("");
              setRouteType("");
              setIsAnyTime("");
              setIdForUpdate("");
              setRouteDetail([]);
              setWorkoutDetail([]);
              setStartTime();
              setIsAnyTime();
              setSelectedGroups([]);
              reset();
            }}
          >
            {t("CANCEL")}
          </CustomButton>
          <CustomButton
            variant="footerBtnCustom"
            type="button"
            opacity={
              whichScreenWork !== 2 ||
              isAddManually ||
              isAddManuallyRoute ||
              (routeDetail?.name && whichScreenRoute === 1)
                ? CONSTANT.OPACITY_RATIO
                : ""
            }
            onClick={() => {
              if (
                !isAddManually &&
                !isAddManuallyRoute &&
                whichScreenWork === 2 &&
                (whichScreenRoute === 2 ||
                  (!routeDetail?.name && whichScreenRoute === 1))
              ) {
                OnSubmit();
              }
            }}
          >
            <p>
              {t("SAVE")} <span>{t("ALL")}</span>
            </p>
          </CustomButton>{" "}
        </div>
      </Form>

      <DeleteWorkoutTypes
        show={showDeleteTraning}
        setShowDelete={setShowDeleteTraning}
        handleWorkoutDelete={handleDeleteTraning}
      />

      <TraningCalender
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        tileDisabled={tileDisabledForStartDate}
        selectedDate={selectedDate}
        dateToDiable={new Date()}
      />
    </div>
  );
}

export default AddTrainingDateTime;
